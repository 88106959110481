import React, { useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import Image from 'gatsby-plugin-sanity-image';
import { Link } from 'gatsby'
import { AnimateSharedLayout, motion } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';

const SliderItem = ({ title, slug, caseImage, excerpt }) => {

  return (
    <div className="w-full md:w-1/2 flex-full pl-10">
      <div className="w-full aspect-w-6 aspect-h-4 relative">
        {caseImage && caseImage.moduleImage.asset && (
          <Link to={`/case-studies/${slug.current}`}>
            <Image {...caseImage.moduleImage} className="h-full w-full absolute object-cover" />
          </Link>
        )}
      </div>
      <div className="w-full mt-10 flex flex-col gap-y-6 justify-between">
        {title && <h4 className="mt-4 font-sans text-xl lg:text-3xl">{title}</h4>}
        {excerpt && <div className="prose prose-lg lg:prose-xl">{excerpt}</div>}
        <Link to={`/case-studies/${slug.current}`} className="font-sans underline lg:text-lg">
          Read More
        </Link>
      </div>
    </div>
  );
};

const SliderItemMobile = ({ title, slug, caseImage, excerpt }) => {

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }} exit={{ opacity: 0 }} className="w-full flex-full mt-12">
      <Link to={`/case-studies/${slug.current}`}>
        <div className="w-full aspect-w-6 aspect-h-4 relative">
          {caseImage && caseImage.moduleImage.asset && (
            <Image {...caseImage.moduleImage} className="h-full w-full absolute object-cover" />
          )}
        </div>
        <div className="w-full mt-10 flex flex-col gap-y-6 justify-between px-gutter">
          {title && <h4 className="mt-4 font-sans text-3xl">
            <span className="text-base font-sans block mb-2">Case Study</span>
            {title}
          </h4>}
          {excerpt && <div className="text-lg">{excerpt}</div>}
        </div>
      </Link>
    </motion.div>
  );
};

const CaseStudySlider = ({ blockConfig, items }) => {

  const [index, setIndex] = useState(0)

  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: 'start',
    containScroll: true,
  });

  const [mobileRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    draggable: false,
    align: 'start',
    containScroll: true,
    startIndex: index,
  });

  return (
    <>
      <BlockWrapper {...blockConfig} className="hidden md:block w-full overflow-x-hidden">
        <h3 className="block w-full text-center mb-12 md:mb-32 text-3xl md:text-4xl lg:text-5xl font-sans">Case Studies</h3>
        {/* Items */}
        <div className="px-gutter w-full">
          <div ref={emblaRef} className="-ml-10">
            <div className="flex">
              {items.map((item, i) => (
                <>
                  <SliderItem key={i} i={i} {...item} />
                </>
              ))}
            </div>
          </div>
        </div>
      </BlockWrapper>

      <BlockWrapper {...blockConfig} className="md:hidden w-full overflow-x-hidden">
        {/* Items */}
        <div className="w-full">
          <div ref={mobileRef}>
            <div key={index} className="flex">
              {items.map((item, i) => (
                <>
                  <SliderItemMobile key={i} {...item} />
                </>
              ))}
            </div>
          </div>

          <AnimateSharedLayout>
            <div className="px-gutter flex flex-row">
              {items.map((item, i) => {
                return (
                  <div className="inline relative">
                    <button key={i} onClick={() => setIndex(i)} className={`focus:outline-none pb-1 px-3 mx-1 font-sans ${index !== i && 'opacity-40'}`}>{i + 1}</button>
                    {index === i && <motion.span layoutId='line' style={{ height: '2px' }} className="w-8 mx-1 bg-logic-teal absolute bottom-0 left-0" />}
                  </div>
                )
              })}
            </div>
          </AnimateSharedLayout>
        </div>
      </BlockWrapper>

    </>
  );
};

export default CaseStudySlider;
