import React, { useState, useEffect } from 'react'
import { Widget } from '@typeform/embed-react'
import { useLocation } from "@reach/router"

import { BlockWrapper } from '../blockWrapper'

const TypeformWidget = ({ formId, height, width, hiddenFieldsUrl, blockConfig }) => {

    const [hiddenFields, setHiddenFields] = useState({})
    const { href } = useLocation();

    useEffect(() => {
        let params = new URLSearchParams(href.split('#').pop());
        var obj = {}
        for (let p of params) {
            obj[p[0]] = p[1]
        }
        return setHiddenFields(obj)
    }, [])

    console.log(hiddenFields)
    return (
        <BlockWrapper {...blockConfig}>
            <Widget id={formId} style={{ width: width, height: height }} className="mx-auto" hidden={hiddenFields} />
        </BlockWrapper>
    )
}

export default TypeformWidget
