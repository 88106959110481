import React, { useState } from 'react';

import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';

const testVars = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const Testimonial = ({ quote, author, ...rest }) => {
  return (
    <motion.div
      className="text-center mx-auto max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      variants={testVars}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      {...rest}
    >
      <h2 className="text-2xl mb-6 md:text-4xl md:leading-normal xl:text-5xl xl:leading-normal">
        {quote}
      </h2>
      <h6 className="font-sans">{author}</h6>
    </motion.div>
  );
};

const TestimonialsBlock = ({ blockConfig, items }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <BlockWrapper className="px-gutter" {...blockConfig}>
      <div className="py-16 md:py-32">
        {items.length > 1 && (
          <AnimateSharedLayout>
            <div className="flex justify-center mb-10">
              {items.map(({ heading }, index) => {
                const isActive = index === activeIndex;
                return (
                  <button
                    className={`mx-2 outline-none focus:outline-none relative pb-2 px-2 font-sans ${
                      isActive ? 'opacity-100' : 'opacity-50'
                    }`}
                    onClick={() => setActiveIndex(index)}
                  >
                    {isActive && (
                      <motion.span
                        className="w-full h-1 bg-logic-teal block absolute bottom-0 left-0"
                        layoutId="line"
                        initial={false}
                      />
                    )}
                    {heading}
                  </button>
                );
              })}
            </div>
          </AnimateSharedLayout>
        )}

        <AnimatePresence exitBeforeEnter>
          <Testimonial {...items[activeIndex]} key={activeIndex} />
        </AnimatePresence>
      </div>
    </BlockWrapper>
  );
};

export default TestimonialsBlock;
