import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { BlockWrapper } from '../blockWrapper';

import { PageLink } from '../../ui';

const IconGrid = ({ blockConfig, callToAction, heading, items }) => {
  return (
    <BlockWrapper className="px-gutter flex flex-col items-center" {...blockConfig}>
      {heading && (
        <h3 className="font-sans text-2xl lg:text-4xl 2xl:text-6xl leading-normal lg:leading-normal 2xl:leading-normal text-center max-w-5xl mb-12 lg:mb-16">
          {heading}
        </h3>
      )}
      <section className="w-full grid grid-cols-2 lg:grid-cols-4 gap gap-y-8 lg:gap-4 text-lg font-sans max-w-6xl mx-auto">
        {items.map((item, index) => {
          const { heading, iconImage } = item;
          return (
            <div className="col-span-1 flex flex-col items-center">
              <div className="text-logic-teal flex items-end w-36 h-24 relative">
                {iconImage && iconImage.asset && (
                  <Image {...iconImage} className="absolute top-0 left-0 object-contain" />
                )}
              </div>
              <h4 className="text-center">{heading}</h4>
            </div>
          );
        })}
      </section>
      {callToAction && (
        <div className="flex w-full justify-center mt-12 lg:mt-28">
          <PageLink className="outline-btn" {...callToAction} />
        </div>
      )}
    </BlockWrapper>
  );
};

export default IconGrid;
