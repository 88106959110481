import React from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';

// Hooks
import { useModal } from '../../../hooks';
import { useSanityPath } from '../../../hooks';

// SVG
import PlayIcon from '../../../svg/play.svg';
import CloseIcon from '../../../svg/close.svg';

// Components
import { BannerBackground } from '../../media/bannerBackground';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { StandardModal } from '../../ui/standardModal';

const ModalVideo = loadable(() => import('../../media/fluidVideo'));

const BannerTouchpoint = ({ subheading, heading, touchpointText, page }) => {
  const path = useSanityPath(page);

  return (
    <Link
      className="px-gutter py-10 lg:py-0 prose prose-lg lg:flex lg:justify-center lg:flex-col lg:bg-transparent lg:hover:bg-logic-teal lg:transition-all xl:prose-xl xl:pl-20 max-w-none"
      to={path}
    >
      <h5 className="uppercase font-bold mb-1 tracking-wide">{subheading}</h5>
      <h3 className="font-sans">{heading}</h3>
      <p>
        {touchpointText.length > 250 ? `${touchpointText.substring(0, 200)}...` : touchpointText}
      </p>
    </Link>
  );
};

const FeatureBanner = ({ bannerBackground, bannerHeading, fullVideo, touchpoints }) => {
  const [isOpen, closeModal, openModal] = useModal(false);

  return (
    <>
      <section className="grid grid-cols-14 lg:grid-rows-feature-banner">
        {/* NB */}
        <div className="col-start-1 col-end-15 h-screen relative row-start-1 bg-black md:h-screen-1/2 lg:h-screen">
          <div className="h-full w-full opacity-60 absolute top-0 left-0">
            {bannerBackground && <BannerBackground {...bannerBackground[0]} />}
          </div>
        </div>
        {/* Banner Text */}
        <div className="col-start-2 col-end-14 row-start-1 h-screen z-20 text-white flex flex-col justify-end pb-10 md:h-screen-1/2 lg:row-start-1 lg:row-end-3 lg:h-screen lg:col-end-7">
          <PortableTextBlock
            text={bannerHeading}
            className="prose prose-lg 2xl:prose-xl mb-4 lg:mb-8"
          />
          {fullVideo && (
            <button
              className="outline-btn flex items-center justify-center text-white self-start px-12"
              onClick={openModal}
            >
              <PlayIcon className="w-3 fill-current mr-4" />
              Play Video
            </button>
          )}
        </div>
        {/* Touchpoints */}
        <div className="bg-logic-teal text-white col-start-1 col-end-15 grid gap-y-4 divide-y divide-current z-20 lg:row-start-2 lg:col-start-9 lg:bg-transparent lg:border-l lg:gap-y-0 ">
          {touchpoints.map((item) => (
            <BannerTouchpoint {...item} />
          ))}
        </div>
      </section>

      {/* Video Modal */}
      <StandardModal isOpen={isOpen}>
        <button
          className="text-white w-8 absolute top-4 right-4 z-50 focus:outline-none"
          onClick={closeModal}
        >
          <CloseIcon />
        </button>
        <div className="w-full h-full absolute grid grid-cols-12 items-center px-gutter z-30">
          <ModalVideo
            url={fullVideo && fullVideo.video}
            width="100%"
            className="home-video aspect-w-16 aspect-h-9 w-full max-h-screen col-start-1 col-end-13 md:col-start-2 md:col-end-12"
            playing={true}
            controls={true}
          />
        </div>
      </StandardModal>
    </>
  );
};

export default FeatureBanner;
