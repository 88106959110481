import React from 'react';

import { container } from './svgIcon.module.css';

export const SvgIcon = ({ icon, className, fillCurrent = true }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: icon }}
      className={`${container} ${fillCurrent && 'fill-current'} ${className && className}`}
    />
  );
};
