import React, { useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';

// SVG
import CloseIcon from '../../../svg/close.svg';

// Components
import { BlockWrapper } from '../blockWrapper';
import { StandardModal } from '../../ui/standardModal';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

import { gridItem } from './productGrid.module.css';

const ProductCard = ({ bgColor, logo, ...rest }) => {
  const { moduleImage } = logo || {};
  return (
    <div className={`cursor-pointer group ${gridItem}`} {...rest}>
      <div
        className="hover:bg-logic-hover aspect-w-1 aspect-h-1 relative"
        style={{ backgroundColor: bgColor.hex }}
      >
        <div className="transition-all duration-200 absolute inset-0 z-0 bg-logic-bg-tint xl:group-hover:bg-transparent"></div>
        {moduleImage && (
          <Image
            {...moduleImage}
            className="w-4/5 h-auto absolute z-10 max-w-xs top-1/2 left-1/2 transition-all duration-200 transform -translate-y-1/2 -translate-x-1/2"
          />
        )}
      </div>
    </div>
  );
};

const ProductModal = ({ bgColor, title, description, logo, onClose }) => {
  const { moduleImage } = logo || {};

  return (
    <div className="w-full h-full bg-white overflow-y-scroll xl:bg-transparent xl:flex xl:items-center">
      <div className="relative grid gap-y-8 lg:grid-cols-2 xl:max-w-7xl xl:mx-auto xl:h-screen-2/3 xl:bg-white xl:overflow-y-scroll">
        <button onClick={onClose} className="w-8 absolute top-4 right-4 z-30">
          <CloseIcon />
        </button>
        <div className="relative w-full xl:sticky xl:h-screen-2/3 xl:top-0" style={{ backgroundColor: bgColor.hex }}>
          <div className="w-full h-96 md:h-screen-1/2 lg:sticky lg:top-0 lg:h-screen xl:h-full flex items-center justify-center">
            {moduleImage && <Image {...moduleImage} className="w-4/5 h-auto max-w-xs" />}
          </div>
        </div>
        <div className="px-gutter pb-7 lg:pt-7 xl:col-start-2 lg:px-20 flex flex-col justify-center">
          <div className="prose prose-lg mb-8">
            <h2 className="font-sans">{title}</h2>
          </div>
          {description && <PortableTextBlock text={description} className="prose prose-lg" />}
        </div>
      </div>
    </div>
  );
};

const ProductGrid = ({ blockConfig, items, title }) => {
  const [activeBio, setActiveBio] = useState(null);

  const closeModal = () => {
    setActiveBio(null);
  };

  return (
    <>
      <BlockWrapper className="px-gutter" {...blockConfig}>
        <div className="text-center font-sans w-full max-w-none mb-12 md:mb-32 text-3xl md:text-4xl lg:text-5xl">
          <h2>{title}</h2>
        </div>
        <div className="grid gap-y-10 grid-cols-2 gap-x-10 lg:grid-cols-3 xl:grid-cols-4">
          {items.map((item, index) => (
            <ProductCard {...item} onClick={() => setActiveBio(index)} />
          ))}
        </div>
      </BlockWrapper>
      <StandardModal isOpen={activeBio !== null}>
        <ProductModal {...items[activeBio]} onClose={closeModal} />
      </StandardModal>
    </>
  );
};

export default ProductGrid;
