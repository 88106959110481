import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import Marquee from "react-fast-marquee";

import { motion } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';

const PartnersCarousel = ({ blockConfig, items }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <Marquee speed={50}>
        {items.map((item, i) => {
          const { moduleImage } = item
          return (
            <div className="w-64 lg:w-1/4 lg:px-20 flex items-center">
              {moduleImage && moduleImage.asset && <Image key={i} {...moduleImage} className="w-40 mx-auto" />}
            </div>
          )
        })}
      </Marquee>
    </BlockWrapper>
  );
};

export default PartnersCarousel;
