import React from 'react';

// Components
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TwoColText = ({ blockConfig, textOne, textTwo }) => {
  return (
    <BlockWrapper className="px-gutter" {...blockConfig}>
      <div className="grid gap-y-10 lg:grid-cols-2 lg:gap-x-24 lg:max-w-6xl lg:mx-auto">
        {textOne && <PortableTextBlock text={textOne} className="prose md:prose-lg" />}
        {textTwo && <PortableTextBlock text={textTwo} className="prose md:prose-lg" />}
      </div>
    </BlockWrapper>
  );
};

export default TwoColText;
