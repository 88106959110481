import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { BackgroundVideo } from './backgroundVideo';

export const BannerBackground = ({ _type, moduleImage, video }) => {
  const BannerComponent = () => {
    switch (_type) {
      case 'imageModule':
        return <Image {...moduleImage} className="h-full w-full absolute object-cover" />;
      case 'videoModule':
        return <BackgroundVideo video={video} />;
      default:
        return null;
    }
  };

  return <BannerComponent />;
};
