import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { Link } from 'gatsby'

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const ImageText = ({ _rawText, image, blockConfig, layout, imageLink, mobileImagePos = 'top' }) => {
  const imageLayout = layout === 'left' ? 'lg:col-end-8' : 'lg:col-start-8';
  const textLayout = layout === 'left' ? 'lg:col-start-9' : 'lg:col-end-7';
  return (
    <BlockWrapper className="grid grid-cols-14 gap-y-6" {...blockConfig}>
      <div
        className={`col-start-1 col-end-15 md:col-start-2 md:col-end-14 self-center ${mobileImagePos !== 'top' && 'row-start-2'
          } lg:row-start-1 ${imageLayout}`}
      >
        {image && image.asset && <Link to={imageLink && `/${imageLink}`}><Image {...image} /></Link>}
      </div>
      <div className={`col-start-2 col-end-14 self-center lg:row-start-1 ${textLayout}`}>
        <PortableTextBlock text={_rawText} className={`prose md:prose-lg max-w-none`} />
      </div>
    </BlockWrapper>
  );
};

export default ImageText;
