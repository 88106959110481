import React from "react"
import Image from "gatsby-plugin-sanity-image"
import { BlockWrapper } from "../blockWrapper"

const ImageBlock = ({ blockConfig, blockImage, layout }) => {
  console.log(blockImage)
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14">
        {blockImage && <Image {...blockImage} />}
      </div>
    </BlockWrapper>
  )
}

export default ImageBlock
