import React from 'react';

import { BannerBackground } from '../../media/bannerBackground';

const PageBanner = ({ bannerBackground, subheading, heading, bannerText }) => {
  return (
    <section className="grid grid-cols-14 bg-black">
      {/* Background */}
      <div className="col-start-1 col-end-15 row-start-1 relative h-banner opacity-60">
        {bannerBackground && <BannerBackground {...bannerBackground[0]} />}
      </div>
      {/* Text */}
      <div className="col-start-2 col-end-14 relative row-start-1 z-20 text-white prose prose-lg 2xl:prose-xl self-end pb-5 md:pb-16">
        {subheading && <h5 className="uppercase font-bold text-sm mb-2">{subheading}</h5>}
        <h1 className="font-sans">{heading}</h1>
        <p>{bannerText}</p>
      </div>
    </section>
  );
};

export default PageBanner;
