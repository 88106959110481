import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TextBlock = ({ _rawText, centerText, blockConfig }) => {
  return (
    <BlockWrapper
      className={`prose md:prose-xl max-w-5xl mx-auto px-gutter xl:px-0 ${centerText && 'text-center'
        }`}
      {...blockConfig}
    >
      <PortableTextBlock text={_rawText} />
    </BlockWrapper>
  );
};

export default TextBlock;
