import React, { useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { motion, AnimatePresence } from 'framer-motion';

// Hooks & Querys
import { useSanityPath } from '../../../hooks';

// Components

import { BlockWrapper } from '../blockWrapper';
import { SvgIcon } from '../../media/svgIcon';

const FeatureBackground = ({ backgroundImage, key }) => {
  const { moduleImage } = backgroundImage || {};
  return (
    <motion.div
      className="hidden md:block absolute w-full h-full z-0"
      key={key}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {moduleImage && moduleImage.asset && (
        <Image {...moduleImage} className="h-full w-full top-0 left-0 object-cover opacity-25" />
      )}
    </motion.div>
  );
};

const FeatureItem = ({ icon, link, heading, featureText, index, activeIndex, ...rest }) => {
  const { linkText, page } = link || {};

  const isActive = activeIndex === index;
  const path = useSanityPath(page);

  return (
    <Link
      to={path}
      className={`grid gap-y-3 lg:gap-x-10 xl:transition-all ${
        isActive ? 'xl:opacity-100' : 'xl:opacity-50'
      }`}
      key={index}
      {...rest}
    >
      <div className="lg:col-start-1">
        <SvgIcon icon={icon} className="w-10 text-logic-teal lg:w-12 lg:text-white" />
      </div>
      <div className="lg:col-start-2">
        <h3
          className={`text-xl font-sans mb-2 text-logic-teal  lg:text-2xl lg:text-white xl:text-3xl xl:transition-colors xl:duration-700 ${
            isActive && 'xl:text-logic-teal'
          }`}
        >
          {heading}
        </h3>
        <p>{featureText}</p>
        <span className="block mt-2 underline font-sans">{linkText}</span>
      </div>
    </Link>
  );
};

const FeaturesBlock = ({ blockConfig, heading, items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <BlockWrapper {...blockConfig}>
      <div className="grid grid-cols-14 bg-logic-navy text-white py-12 gap-y-10 relative lg:py-24 lg:gap-y-20 xl:min-h-screen">
        <div className="prose prose-lg lg:prose-xl xl:prose-2xl col-start-2 col-end-14 z-10 xl:self-end">
          <h2 className="font-sans">{heading}</h2>
        </div>
        {/* Items */}
        <div className="col-start-2 col-end-14 grid gap-y-10 z-10 md:col-end-9 lg:col-end-7  self-start">
          {items.map((item, index) => (
            <FeatureItem
              {...item}
              index={index}
              activeIndex={activeIndex}
              onMouseEnter={() => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(0)}
            />
          ))}
        </div>
        <AnimatePresence exitBeforeEnter>
          <FeatureBackground {...items[activeIndex]} key={activeIndex} activeIndex={activeIndex} />
        </AnimatePresence>
      </div>
    </BlockWrapper>
  );
};

export default FeaturesBlock;
