import React from "react";
import PropTypes from "prop-types";

import {
  ContactBlock,
  FeatureBanner,
  PageBanner,
  PartnersCarousel,
  CardCarousel,
  FeaturesBlock,
  TestimonialsBlock,
  TextBlock,
  TwoColText,
  BioGrid,
  ProductGrid,
  ImageText,
  ImageBlock,
  VideoBlock,
  ImageSlider,
  CaseStudySlider,
  IconGrid,
  SolutionsBlock,
  TypeformWidget,
  CareerBlock,
} from "./blocks";

const BlockZone = ({ blocks }) => {
  const block = blocks.map((block) => {
    switch (block._type) {
      case "featureBanner":
        return <FeatureBanner {...block} />;
      case "pageBanner":
        return <PageBanner {...block} />;
      case "partnersCarousel":
        return <PartnersCarousel {...block} />;
      case "cardCarousel":
        return <CardCarousel {...block} />;
      case "featuresBlock":
        return <FeaturesBlock {...block} />;
      case "testimonialsBlock":
        return <TestimonialsBlock {...block} />;
      case "textBlock":
        return <TextBlock {...block} />;
      case "twoColText":
        return <TwoColText {...block} />;
      case "bioGrid":
        return <BioGrid {...block} />;
      case "productGrid":
        return <ProductGrid {...block} />;
      case "imageText":
        return <ImageText {...block} />;
      case "imageBlock":
        return <ImageBlock {...block} />;
      case "videoBlock":
        return <VideoBlock {...block} />;
      case "imageSlider":
        return <ImageSlider {...block} />;
      case "caseStudySlider":
        return <CaseStudySlider {...block} />;
      case "iconGrid":
        return <IconGrid {...block} />;
      case "contactBlock":
        return <ContactBlock {...block} />;
      case "solutionsBlock":
        return <SolutionsBlock {...block} />;
      case "typeform":
        return <TypeformWidget {...block} />;
      case "careerBlock":
        return <CareerBlock {...block} />;
      default:
        return null;
    }
  });
  return <>{block}</>;
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
