import React from 'react';
import loadable from '@loadable/component';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { BlockWrapper } from '../blockWrapper';

const SolutionsMobile = loadable(() => import('../solutionsBlocks/solutionsMobile'));
const SolutionsDesktop = loadable(() => import('../solutionsBlocks/solutionsDesktop'));

const SolutionsBlock = ({ blockConfig, ...props }) => {
  const breakpoints = useBreakpoint();

  return (
    <BlockWrapper {...blockConfig}>
      {breakpoints.sm ? <SolutionsMobile {...props} /> : <SolutionsDesktop {...props} />}
    </BlockWrapper>
  );
};

export default SolutionsBlock;
