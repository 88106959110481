import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import MapGL, { Marker } from 'react-map-gl';

import LogoMark from '../../../svg/logoMark.svg';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { Contact } from '../../forms/contact';

const ContactMap = ({ lat, lng }) => {
  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: lng,
    zoom: 16,
  });

  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  return (
    <div className="w-full h-full absolute">
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/luke-doig-brave/cknp4glio3vrz17nt17eim3ej"
        mapboxApiAccessToken="pk.eyJ1IjoibHVrZS1kb2lnLWJyYXZlIiwiYSI6ImNqdnJmNXlyaTJkeWUzeXFqa2g1NDE1NDMifQ.9gZ0DygX_glVXLY6IsHjpA"
        onViewportChange={handleViewportChange}
      >
        <Marker latitude={lat} longitude={lng} className="w-16 h-16">
          <LogoMark />
        </Marker>
      </MapGL>
    </div>
  );
};

const ContactBlock = ({ blockConfig, contactDetails, successText, location }) => {
  const [activeTab, setActiveTab] = useState(0);

  console.log(location)

  return (
    <BlockWrapper className="grid grid-cols-14 gap-y-10" {...blockConfig}>
      <div className="col-start-2 col-end-14 row-start-1 lg:col-end-7 lg:pl-12">
        <PortableTextBlock className="prose lg:prose-lg" text={contactDetails} />
      </div>
      <div className="col-start-2 col-end-14 row-start-2 lg:row-start-1 lg:col-start-8">
        <AnimateSharedLayout>
          <nav className="flex mb-10">
            <button
              className={`mr-6 text-lg font-sans relative transition-all py-2 focus:outline-none ${activeTab < 1 ? 'opacity-100' : 'opacity-40'
                }`}
              onClick={() => setActiveTab(0)}
            >
              Contact form
              {activeTab < 1 && (
                <motion.span
                  className="w-full h-1 bg-logic-teal block absolute bottom-0"
                  layoutId="line"
                  initial={false}
                />
              )}
            </button>
            <button
              className={`mr-3 text-lg font-sans relative py-2 focus:outline-none ${activeTab > 0 ? 'opacity-100' : 'opacity-40'
                }`}
              onClick={() => setActiveTab(1)}
            >
              Map
              {activeTab > 0 && (
                <motion.span
                  className="w-full h-1 bg-logic-teal block absolute bottom-0"
                  layoutId="line"
                  initial={false}
                />
              )}
            </button>
          </nav>
        </AnimateSharedLayout>

        <AnimatePresence exitBeforeEnter>
          {activeTab < 1 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={activeTab}
            >
              <Contact successText={successText} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={activeTab}
              className="w-full aspect-w-8 aspect-h-6"
            >
              {location && <ContactMap {...location} />}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </BlockWrapper>
  );
};

export default ContactBlock;
