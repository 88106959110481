import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { BlockWrapper } from '../blockWrapper';

import { useEmblaCarousel } from 'embla-carousel/react';

import { useSanityPath } from '../../../hooks';

const CarouselCard = ({ cardImage, heading, link }) => {
  const { moduleImage } = cardImage || {};
  const { page, linkText } = link;

  const path = useSanityPath(page);
  return (
    <Link className="w-full md:w-1/2 flex-full pl-10" to={path}>
      <div className="w-full aspect-w-1 aspect-h-1 relative">
        {moduleImage && moduleImage.asset && (
          <Image {...moduleImage} className="h-full w-full absolute object-cover" />
        )}
      </div>
      <div className="w-full mt-2 lg:mt-10 flex justify-between items-end">
        <h3 className="text-xl font-sans lg:text-3xl w-1/2 lg:w-full">{heading}</h3>
        <h4 className="underline text-lg font-sans lg:text-xl">{linkText}</h4>
      </div>
    </Link>
  );
};

const CardCarousel = ({ blockConfig, items, heading }) => {
  // Embla Setup
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: 'start',
    containScroll: true,
  });

  return (
    <BlockWrapper className=" overflow-hidden" {...blockConfig}>
      <div className="prose prose-lg md:prose-xl text-center w-full max-w-none mb-24 grid grid-cols-14">
        <h2 className="font-sans col-start-2 col-end-14">{heading}</h2>
      </div>
      {/* Items */}
      <div className="px-gutter w-11/12">
        <div ref={emblaRef} className="-ml-10">
          <div className="flex">
            {items.map((item) => (
              <CarouselCard {...item} />
            ))}
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default CardCarousel;
