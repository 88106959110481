import React, { useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';

// SVG
import CloseIcon from '../../../svg/close.svg';

// Components
import { BlockWrapper } from '../blockWrapper';
import { StandardModal } from '../../ui/standardModal';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const BioCard = ({ bioImage, firstName, lastName, position, ...rest }) => {
  const { moduleImage } = bioImage || {};
  return (
    <div className="cursor-pointer group" {...rest}>
      <div className="aspect-w-1 aspect-h-1 relative">
        <Image {...moduleImage} className="w-full h-full object-cover absolute top-0 left-0" />
        <div className="transition-all duration-200 absolute bottom-0 left-0 right-0 top-auto px-6 py-8 h-auto z-10 text-white bg-logic-teal bg-opacity-70 xl:opacity-0 xl:group-hover:opacity-100">
          <div className="transition-all delay-100 duration-300 ease-in-out xl:transform xl:opacity-0 xl:group-hover:opacity-100 xl:translate-y-4 xl:group-hover:translate-y-0">
            <h4 className=" text-2xl font-sans pb">
              {firstName} {lastName}
            </h4>
            <h5 className=" text-base font-sans">{position}</h5>
            <div className="hidden underline text-base font-sans">About {firstName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BioModal = ({ firstName, lastName, description, position, bioImage, onClose }) => {
  const { moduleImage } = bioImage || {};

  return (
    <div className="w-full h-full bg-white bio-right overflow-y-scroll lg:bg-transparent lg:flex lg:items-center">
      <div className="w-full lg:grid grid-cols-12">
        <div className="col-start-1 col-end-13 xl:col-start-2 xl:col-end-12 relative grid gap-y-8 lg:grid-cols-2 lg:max-w-7xl lg:mx-6 xl:mx-auto lg:h-screen-2/3 lg:bg-white bio-right lg:overflow-y-scroll">
          <button onClick={onClose} className="w-8 absolute top-4 right-4 z-30">
            <CloseIcon />
          </button>
          <div className="relative w-full lg:sticky lg:h-screen-2/3 lg:top-0">
            <div className="w-full h-screen-1/2 md:h-screen-2/3 lg:sticky lg:top-0 lg:h-screen lg:h-full">
              {moduleImage && moduleImage.asset && (
                <Image
                  {...moduleImage}
                  className="w-full h-full object-cover absolute top-0 left-0"
                />
              )}
            </div>
          </div>
          <div className="px-gutter pb-7 lg:pt-7 lg:col-start-2 xl:px-20">
            <div className="prose prose-lg mb-8 lg:mt-44">
              <h2 className="font-sans bio-title">
                {firstName} {lastName}
              </h2>
              <h3 className="font-sans">{position}</h3>
            </div>
            {description && <PortableTextBlock text={description} className="prose prose-lg lg:mt-24" />}
          </div>
        </div>
      </div>
    </div>
  );
};

const BioGrid = ({ blockConfig, items, title }) => {
  const [activeBio, setActiveBio] = useState(null);

  const closeModal = () => {
    setActiveBio(null);
  };

  return (
    <>
      <BlockWrapper className="px-gutter" {...blockConfig}>
        <div className="prose prose-lg md:prose-xl text-left w-full max-w-none mb-10 lg:mb-16">
          <h2 className="font-sans">{title}</h2>
        </div>
        <div className="grid gap-y-10 md:grid-cols-2 gap-x-10 lg:grid-cols-3">
          {items.map((item, index) => (
            <BioCard {...item} onClick={() => setActiveBio(index)} />
          ))}
        </div>
      </BlockWrapper>
      <StandardModal isOpen={activeBio !== null}>
        <BioModal {...items[activeBio]} onClose={closeModal} />
      </StandardModal>
    </>
  );
};

export default BioGrid;
